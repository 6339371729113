import React, { useEffect } from 'react';
import './Home.scss';
import Layout from '../../Components/Layout/Layout.js';
import { TypeAnimation } from "react-type-animation";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';

export default function Home() {

    const navigate = useNavigate();
    const featuresData = [
        { icon: 'Assets/Images/economist.svg', title: 'Market Analysis by Economist', description: 'Enjoy daily in-depth market analysis by our experienced economists and get updates on the latest trends.' },
        { icon: 'Assets/Images/regulated-broker.svg', title: 'Trade with a Reputed Broker', description: 'Pioneer Capital is a reputed broker where clients can confidently trade, assured of a secure and reliable environment compliant with stringent financial guidelines.' },
        { icon: 'Assets/Images/wide-range.svg', title: 'Wide-range Instruments', description: 'Diversify your portfolio with our wide-ranged trading instruments like Forex, Crypto, Metal, Indices, and CFDs.' },
        { icon: 'Assets/Images/segregated-accounts.svg', title: 'Segregated Accounts', description: 'We ensure that your funds are kept in segregated accounts with prominent banks so that you can trade without any worries.' },
        { icon: 'Assets/Images/exceptional-market.svg', title: 'Exceptional Market Conditions', description: 'Pioneer Capital offers unparalleled market conditions at all times to allow both veterans and novice traders to maximize profits.' },
        { icon: 'Assets/Images/extensive-funding.svg', title: 'Extensive Funding Options', description: 'Quit fretting about withdrawals with Pioneer Capital. Deposit your funds smoothly and withdraw them anytime.' },
    ];

    const StepsData = [
        { step: '01', title: 'Register', description: 'Register for the trading account by filling in your details', icon: 'Assets/Images/open-account.svg' },
        { step: '02', title: 'Fund', description: 'Fund your trading account with our wide array of crypto funding options', icon: 'Assets/Images/transfer.svg' },
        { step: '03', title: 'Trade', description: 'Access 400+ instruments across all markets through our futuristic online forex trading platform', icon: 'Assets/Images/trade.svg' },
    ];

    const ReviewData = [
        { title: '$130.33 Million', description: 'Trade volume in Q3 2024' },
        { title: '90,730', description: 'Engaged Clients as of Q3 2024' },
        { title: '$39.72 Million', description: 'Decentralized Withdrawals in Q3 2024' },
        { title: '$8.6 Million', description: 'Affiliate Rewards in Q3 2024' },
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Layout>
            <Helmet>
                <title>Pioneer Capital Markets</title>
            </Helmet>
            <div className='home-container'>
                <div className='start-trading-wrapper'>
                    <div className='side-padding'>
                        <div className='my-5 py-md-4'>
                            <div className='d-flex'>
                                <div className='global-capsule py-2 px-3 d-flex align-items-center'>
                                    <img className='rotate linear infinite' src='Assets/Images/globel.svg' alt='icon' />
                                    <p className='m-0 ml-2'>First of It’s Kind</p>
                                </div>
                            </div>
                            <p className='main-text my-4'>Investing Is Even Better Now</p>
                            <p className='m-0 short-description'>Providing you with the opportunity to invest in more than 400+ assets for continuous income</p>
                            <div className='text-left mt-5'>
                                <a href='https://cabinet.pioneercapitalmarkets.com/?tab=register'><button className='btn-blue-bg mr-4 mb-3'>Get Started</button></a>
                                {/* <button className='btn-blue-bg mr-4 mb-3' onClick={() => navigate('/login')}>Open Live Account</button> */}
                                {/* <button className='btn-blue-border' onClick={() => navigate('/login')}>Open Demo Account</button> */}
                            </div>
                            <div className='shield-container pt-5 mt-md-5'>
                                <img height={40} className='cursor-pointer' src='Assets/Images/shield-icon.svg' alt='icon' />
                                <p className='m-0 ml-2'>
                                    <TypeAnimation
                                        sequence={['7365387528@&*&WYBgsejlWdsRfGd', 'Highly Encrypted & Secured']}
                                        cursor={false}
                                        speed={65}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='payment-method'>
                    <div className='side-padding'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='title-blue mb-4 mt-5'>
                                    <p className='m-0'>Payment method</p>
                                </div>
                                <p className='main-text my-4'>24/7 withdrawal at your control</p>
                                <p className='m-0 short-description'>We allow 24/7 automatic crypto deposits and withdrawals, making it easier for any trader around the globe to trade with us while keeping absolute control of their funds.</p>
                            </div>
                            <div className='col-md-4 pt-5'>
                                <div className='learn-box p-3 mt-4 mt-md-0'>
                                    <p className='m-0'>Tired of trading with <span>centralised platforms</span> and <span>multiple humans</span></p>
                                    {/* <button className='btn-blue-border py-2 mt-4' onClick={() => navigate('/login')}>Learn How</button> */}
                                    <a href='https://cabinet.pioneercapitalmarkets.com/?tab=register'><button className='btn-blue-bg py-2 mt-4'>Get Started</button></a>
                                </div>
                            </div>
                        </div>
                        <div className='slider-wrapper my-5'>
                            <div className='devider px-5 w-50 mx-auto'></div>
                            <div class="slider">
                                <div class="slide-track">
                                    <div class="slide d-flex align-items-center">
                                        <img src="Assets/Images/binancecoin.svg" alt="icon" />
                                    </div>
                                    <div class="slide d-flex align-items-center">
                                        <img src="Assets/Images/tethercoin.svg" alt="icon" />
                                    </div>
                                    <div class="slide d-flex align-items-center">
                                        <img src="Assets/Images/usdcoin.svg" alt="icon" />
                                    </div>
                                    <div class="slide d-flex align-items-center">
                                        <img src="Assets/Images/bitcoin.svg" alt="icon" />
                                    </div>
                                    <div class="slide d-flex align-items-center">
                                        <img src="Assets/Images/binancecoin.svg" alt="icon" />
                                    </div>
                                    <div class="slide d-flex align-items-center">
                                        <img src="Assets/Images/tethercoin.svg" alt="icon" />
                                    </div>
                                    <div class="slide d-flex align-items-center">
                                        <img src="Assets/Images/usdcoin.svg" alt="icon" />
                                    </div>
                                    <div class="slide d-flex align-items-center">
                                        <img src="Assets/Images/bitcoin.svg" alt="icon" />
                                    </div>
                                    <div class="slide d-flex align-items-center">
                                        <img src="Assets/Images/binancecoin.svg" alt="icon" />
                                    </div>
                                    <div class="slide d-flex align-items-center">
                                        <img src="Assets/Images/tethercoin.svg" alt="icon" />
                                    </div>
                                    <div class="slide d-flex align-items-center">
                                        <img src="Assets/Images/usdcoin.svg" alt="icon" />
                                    </div>
                                    <div class="slide d-flex align-items-center">
                                        <img src="Assets/Images/bitcoin.svg" alt="icon" />
                                    </div>
                                </div>
                            </div>
                            <div className='devider px-5 w-50 mx-auto'></div>
                        </div>
                        <div className='benefit-container'>
                            <div className='m-2'>
                                <p className='m-0 title'>400+</p>
                                <p className='m-0 description'>Instruments</p>
                            </div>
                            <div className='m-2'>
                                <p className='m-0 title'>0.01</p>
                                <p className='m-0 description'>Micro lot trading</p>
                            </div>
                            <div className='m-2'>
                                <p className='m-0 title'>{`<14ms`}</p>
                                <p className='m-0 description'>Ultra-fast execution </p>
                            </div>
                            <div className='m-2'>
                                <p className='m-0 title'>0%</p>
                                <p className='m-0 description'>Funding Fee</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='get-started my-5 py-5'>
                    <div className='side-padding'>
                        <div className='row'>
                            <div className='col-md-4 text-left'>
                                <p className='m-0 short-description'>Solving a wide range of drawbacks from multiple trading platforms.</p>
                                <a href='https://cabinet.pioneercapitalmarkets.com/?tab=register'>
                                <button className='btn-blue-border py-2 mt-4'>Get Started</button>
                                </a>
                            </div>
                            <div className='col-md-8 mt-4 mt-md-0'>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>Payment method</p>
                                </div>
                                <p className='main-text my-4'>Unlock the Powerful Benefits & Trade with Conviction</p>
                            </div>
                        </div>
                        <div className='feature-card-wrapper mt-5'>
                            {
                                featuresData?.map((item, index) => (
                                    <div key={index} className='feature-card pb-3 pt-4 pr-3 my-4'>
                                        <div className='icon-wrapper'>
                                            <img height={40} className='cursor-pointer' src={item?.icon} alt='icon' />
                                        </div>
                                        <div className='feature-info'>
                                            <p className='my-3 title'>{item?.title}</p>
                                            <p className='my-2 description'>{item?.description}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='account-types'>
                    <div className='side-padding'>
                        <div className='py-4 head'>
                            <div>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>Account Types</p>
                                </div>
                                <p className='main-title'>Trading Accounts Tailored For Your Unique Trading Needs</p>
                            </div>
                            <div className='text-right w-30 mt-5 mt-md-0'>
                                <p className='overview-head-text mb-4'>Relish our hassle free <span> withdrawals</span> with no <span> manual processing.</span></p>
                                <div className='mt-5 pt-md-5 d-flex justify-content-end'>
                                    <a href='https://cabinet.pioneercapitalmarkets.com/?tab=register'><button className='btn-blue-bg mt-md-5'>Get Started</button></a>
                                    {/* <button className='btn-blue-bg mt-md-5' onClick={() => navigate('/login')}>Get Started</button> */}
                                    {/* <button className='btn-blue-border mt-md-5 ml-3' onClick={() => navigate('/login')}>Open Demo Account</button> */}
                                </div>
                            </div>
                        </div>
                        <div className='account-card-wrapper row m-0'>
                        <div className='col-md-4 my-3'>
                                <div className='account-card px-3 py-4'>
                                    <div>
                                        <div className='type-capsule d-flex'>
                                            <p className='m-0 py-1 px-3'>Micro</p>
                                        </div>
                                        <div className='d-flex align-items-end mt-3 deposit-value'>
                                            <div className='d-flex align-items-top'>
                                                <div className='icon'>$</div>
                                                <div className='value'>50</div>
                                            </div>
                                            <div className='description ml-2'>min deposit</div>
                                        </div>
                                        <div className='info col-md-auto mt-3' >
                                            <p>Our withdrawals are carried out in seconds</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='highlights-container'>
                                            <p className='title mb-4'>Highlights</p>
                                            <div className='d-flex align-items-center mb-2'><img className='mr-3' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0 highlights-text'>Spread starting from 1 pip</p></div>
                                            <div className='d-flex align-items-center mb-2'><img className='mr-3' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0 highlights-text'>Low Commission</p></div>
                                            <div className='d-flex align-items-center mb-2'><img className='mr-3' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0 highlights-text'>Leverage Up to 1:500</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 my-3'>
                                <div className='account-card px-3 py-4'>
                                    <div>
                                        <div className='type-capsule d-flex'>
                                            <p className='m-0 py-1 px-3'>Standard</p>
                                        </div>
                                        <div className='d-flex align-items-end mt-3 deposit-value'>
                                            <div className='d-flex align-items-top'>
                                                <div className='icon'>$</div>
                                                <div className='value'>100</div>
                                            </div>
                                            <div className='description ml-2'>min deposit</div>
                                        </div>
                                        <div className='info col-md-auto mt-3' >
                                            <p>Our withdrawals are carried out in seconds</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='highlights-container'>
                                            <p className='title mb-4'>Highlights</p>
                                            <div className='d-flex align-items-center mb-2'><img className='mr-3' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0 highlights-text'>Spread starting from 1 pip</p></div>
                                            <div className='d-flex align-items-center mb-2'><img className='mr-3' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0 highlights-text'>Low Commission</p></div>
                                            <div className='d-flex align-items-center mb-2'><img className='mr-3' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0 highlights-text'>Leverage 1:500</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 my-3'>
                                <div className='account-card px-3 py-4'>
                                    <div>
                                        <div className='type-capsule d-flex'>
                                            <p className='m-0 py-1 px-3'>Pro</p>
                                        </div>
                                        <div className='d-flex align-items-end mt-3 deposit-value'>
                                            <div className='d-flex align-items-top'>
                                                <div className='icon'>$</div>
                                                <div className='value'>500</div>
                                            </div>
                                            <div className='description ml-2'>min deposit</div>
                                        </div>
                                        <div className='info col-md-auto mt-3' >
                                            <p>Our withdrawals are carried out in seconds</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='highlights-container'>
                                            <p className='title mb-4'>Highlights</p>
                                            <div className='d-flex align-items-center mb-2'><img className='mr-3' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0 highlights-text'>Spread starting from Zero</p></div>
                                            <div className='d-flex align-items-center mb-2'><img className='mr-3' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0 highlights-text'>Low Commission</p></div>
                                            <div className='d-flex align-items-center mb-2'><img className='mr-3' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0 highlights-text'>Leverage 1:500</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='downloads-container py-5'>
                    <div className='side-padding'>
                        <div className='mt-4 mt-md-0 pt-5'>
                            <div className='title-white mb-4'>
                                <p className='m-0'>Download Apps</p>
                            </div>
                            <p className='main-title'>Ready to begin your trading journey?</p>
                        </div>
                        <div className='description'>Set up your trading account in 3 easy steps!</div>
                        <div className='platform-card-wrapper mt-5 pb-5'>
                            <a href='https://download.mql5.com/cdn/web/metaquotes.ltd/mt5/mt5setup.exe?utm_source=www.metatrader5.com&utm_campaign=download'>
                            <div className='platform-card px-3 py-2 d-flex mr-5 my-3'>
                                <img height={32} src='Assets/Images/window.svg' alt='icon' />
                                <div className='ml-2'>
                                    <p className='m-0 text'>Download</p>
                                    <p className='m-0 plateform'>For Windows</p>
                                </div>
                            </div>
                            </a>
                            <a href='https://apps.apple.com/in/app/metatrader-5/id413251709'>
                            <div className='platform-card px-3 py-2 d-flex mr-5 my-3'>
                                <img height={32} src='Assets/Images/apple.svg' alt='icon' />
                                <div className='ml-2'>
                                    <p className='m-0 text'>Download on the</p>
                                    <p className='m-0 plateform'>App Store</p>
                                </div>
                            </div>
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&hl=en'>
                            <div className='platform-card px-3 py-2 d-flex mr-5 my-3'>
                                <img height={32} src='Assets/Images/android.svg' alt='icon' />
                                <div className='ml-2'>
                                    <p className='m-0 text'>Get it on</p>
                                    <p className='m-0 plateform'>Google Play</p>
                                </div>
                            </div></a>
                        </div>
                    </div>
                </div>

                <div className='start-steps py-5'>
                    <div className='side-padding'>
                        <div className='py-4 head'>
                            <div>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>Get Started</p>
                                </div>
                                <p className='main-text'>How to get started with Pioneer Capital</p>
                            </div>
                            <div className='text-right w-30 mt-5 mt-md-0 d-flex align-items-end'>
                            <a href='https://cabinet.pioneercapitalmarkets.com/?tab=register'>
                                <button className='btn-blue-bg'>Get Started</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='position-relative'>
                        <div className='connection-line'></div>
                        <div className='side-padding'>
                            <div className='step-wrapper'>
                                {
                                    StepsData?.map((step, index) => (
                                        <div className='process-card p-3 my-3' key={index}>
                                            <div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <img height={32} width={32} className='mr-4 cursor-pointer' src={step?.icon} alt='icon' />
                                                    <p className='step-no m-0'>{step?.step}</p>
                                                </div>
                                                <div className='mt-3'>
                                                    <p className='title mb-2 mt-2'>{step?.title}</p>
                                                    <p className='description m-0'>{step?.description}</p>
                                                </div>
                                            </div>
                                            <div className='connection-dot'></div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='join-pioneer-community'>
                    <div className='side-padding'>
                        <div className='mt-4 mt-md-0 pt-5'>
                            <div className='title-white mb-4'>
                                <p className='m-0'>Why join Pioneer Capital community</p>
                            </div>
                            <p className='main-title'>We provide the best opportunities to traders of all expertise levels to get a stronghold of all markets.</p>
                        </div>
                    </div>
                </div>

                <div className='features-component py-5'>
                    <div className='side-padding'>
                        <div className='pt-5 text-center'>
                            <div>
                                <div className='d-flex justify-content-center'>
                                    <div className='title-blue mb-4'>
                                        <p className='m-0'>Pioneer Capital</p>
                                    </div>
                                </div>
                                <p className='main-title'>Where Client Trust Meets Excellence</p>
                                <div className='d-flex justify-content-center'>
                                    <p className='description w-75'>Our figures echo the steadfast faith our clients bestow upon us. Dive deep into the financial currents that position us at the pinnacle of the trading universe.</p>
                                </div>
                            </div>
                        </div>
                        <div className='row m-0 p-0 details-card-container'>
                            {
                                ReviewData?.map((feature, index) => (
                                    <div key={index} className={`col-md-6 my-3 my-md-4 ${index % 2 == 0 ? 'pl-md-0 pr-md-4' : 'pr-md-0 pl-md-4'}`}>
                                        <div className='details-card p-2 p-md-3'>
                                            <p className='title mb-2'>{feature?.title}</p>
                                            <p className='description m-0'>{feature?.description}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='start-with-us pt-5'>
                    <div className='side-padding'>
                        <div className='py-5 head'>
                            <div>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>Get Started with us</p>
                                </div>
                                <p className='main-title'>Ready to step into the trading world? Open an account within minutes!</p>
                            </div>
                            <div className='text-right w-30 mt-5 mt-md-0'>
                                <p className='head-text mb-4'>Relish smooth trading experience with a reliable ECN forex broker and stay ahead of the market with our powerful trading tools.</p>
                                <div className='mt-4 d-flex justify-content-end'>
                                <a href='https://cabinet.pioneercapitalmarkets.com/?tab=register'>
                                    <button className='btn-blue-bg'>Get Started</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='marquee-container py-1'>
                        <marquee className='pb-0 pt-1 m-0'>
                            <div className='d-flex'>
                                <div className='d-flex align-items-center my-2 mr-4'><img className='mr-2' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0'>Secure your funds with robust cybersecurity innovations</p></div>
                                <div className='d-flex align-items-center my-2 mr-4'><img className='mr-2' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0'>Secure your funds with robust cybersecurity innovations</p></div>
                                <div className='d-flex align-items-center my-2'><img className='mr-2' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0'>Trade over 200 CFDs and other assets like Forex, Crypto, Metals, Indices</p></div>
                            </div>
                        </marquee>
                    </div>
                </div>
            </div>
        </Layout>
    )
}