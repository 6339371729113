import React, { useState } from "react";
import "./Header.scss";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { NavbarData } from "./NavData.js";

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);

  const isActiveUrl = (path) => {
    let active = false;

    path?.map((item) => {
      if (item == location?.pathname) {
        active = true;
      }
    });
    return active;
  };

  return (
    <div className="header-container">
      <div className="header-top">
        <div className="side-padding">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div
                className="cursor-pointer d-flex align-items-center"
                onClick={() => navigate("/contactUs")}
              >
                <img src="Assets/Images/question-mark-icon.svg" alt="icon" />
                <p className="m-0 ml-2">Help Center</p>
              </div>
              <div className="cursor-pointer ml-2 ml-md-4"></div>
            </div>
            {/* ---------- Temporary removed ---------- */}
            {/* <div>
              <a>
                <img height={24} width={24} className='mx-1 mx-md-4 cursor-pointer' src='Assets/Images/instagram.svg' alt='icon' />
              </a>
              <a>
                <img height={24} width={24} className='mx-1 mx-md-4 cursor-pointer' src='Assets/Images/linkedin.svg' alt='icon' />
              </a>
              <a>
                <img height={24} width={24} className='mx-1 mx-md-4 cursor-pointer' src='Assets/Images/facebook.svg' alt='icon' />
              </a>
              <a>
                <img height={20} width={20} className='mx-1 mx-md-4 cursor-pointer' src='Assets/Images/twitter-x.svg' alt='icon' />
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="header-main">
        <div className="side-padding header-wrapper py-2">
          <div className="d-flex justify-content-between">
            <img
              onClick={() => navigate("/")}
              className="cursor-pointer"
              height="50"
              src="Assets/Logo/Logo.png"
              alt="icon"
            />
            <div
              className="bar-icon"
              onClick={() => setOpenSidebar(!openSidebar)}
            >
              ≡
            </div>
          </div>
          <div>
            <Navbar className="main-navbar p-0" collapseOnSelect>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  {NavbarData?.map((nav, index) =>
                    nav?.children ? (
                      <div
                        className={`mx-2 mx-lg-3 d-flex ${
                          isActiveUrl(nav?.activeUrl) ? "active-nav" : null
                        }`}
                      >
                        <NavDropdown
                          key={index}
                          title={nav?.title}
                          id="basic-nav-dropdown"
                        >
                          {nav?.children?.map((child, index) => (
                            <NavDropdown.Item
                              key={index}
                              eventKey={child?.title + "-" + index}
                              onClick={() => navigate(child?.url)}
                            >
                              {child?.title}
                            </NavDropdown.Item>
                          ))}
                        </NavDropdown>
                        <div className="active-dot d-none">●</div>
                      </div>
                    ) : (
                      <div
                        className={`mx-2 mx-lg-3 d-flex ${
                          isActiveUrl(nav?.activeUrl) ? "active-nav" : null
                        }`}
                      >
                        <Nav.Link
                          key={index}
                          eventKey={nav?.title + "-" + index}
                          onClick={() => navigate(nav?.url)}
                        >
                          {nav?.title}
                        </Nav.Link>
                        <div className="active-dot d-none">●</div>
                      </div>
                    )
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
          <div className="login-controls">
            <a href="https://cabinet.pioneercapitalmarkets.com/?tab=login">
              <button className="btn-blue-bg px-4">Login</button>
            </a>

            {/* <button className='btn-blue-bg px-4' onClick={() => navigate('Login')}>Login</button> */}
          </div>
        </div>
      </div>
      {openSidebar && (
        <div className="mobi-sidebar d-flex">
          <div className="nav-drawwer">
            <div className="text-right my-3 mx-2">
              <button
                className="btn-close"
                onClick={() => setOpenSidebar(!openSidebar)}
              >
                ✖
              </button>
            </div>
            {NavbarData?.map((nav, index) =>
              nav?.children ? (
                <NavDropdown
                  key={index}
                  title={nav?.title}
                  id="basic-nav-dropdown"
                  className="mx-3"
                >
                  {nav?.children?.map((child, index) => (
                    <NavDropdown.Item
                      key={index}
                      eventKey={child?.title + "-" + index}
                      onClick={() => navigate(child?.url)}
                    >
                      {child?.title}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              ) : (
                <Nav.Link
                  className="mx-3"
                  key={index}
                  eventKey={nav?.title + "-" + index}
                  onClick={() => navigate(nav?.url)}
                >
                  {nav?.title}
                </Nav.Link>
              )
            )}
            <div className="mt-5 mx-4">
              <button
                className="btn-blue-bg px-4"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
